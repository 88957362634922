/**
 */
var modules = modules || {};
modules.accessrulesShared = function () {
    SHIFT_CATEGORY_SORT = {
        'BREAKFAST': 0,
        'BRUNCH': 1,
        'LUNCH': 2,
        'DAY': 3,
        'DINNER': 4,
        'LEGACY': 5,
    }

    var shiftCategoryOptions = {
        'BREAKFAST': 'Breakfast',
        'BRUNCH': 'Brunch',
        'LUNCH': 'Lunch',
        'DAY': 'Day',
        'DINNER': 'Dinner',
        'LEGACY': 'Night',
    };

    var fetch_data = function (venue_name, date, client_update_function) {
        $.when(
            $.ajax({
                url: '/api-yoa/booking_access',
                data: {
                    venue: venue_name,
                    week_of: date
                }
            }),
            $.ajax({
                url: '/api-yoa/shifts/schedule',
                data: {
                    venue: venue_name,
                    week_of: date
                }
            })
        ).then(function (response1, response2) {
                response1 = _.first(response1);
                response2 = _.first(response2);
                if (response1.status != '200') {
                    UserNotificationInterop.error(response1.msg);
                } else {
                    client_update_function(response1, response2);
                }
            }
        );
    };

    var fetch_upsells = function(venue_name, upsells, upsellsToInventory) {
        $.ajax({
            url: '/api-yoa/venue/upsells/list',
            data: {
                venue: venue_name,
            },
            success: function (response) {
                if (response.status != '200') {
                    Interface._alert(response.msg);
                } else {
                    upsells.categories = _.reduce(response.data.categories, (result, category) => {
                      const categoryLabel = category.is_required ? `${category.name} (required)` : category.name
                      result[category.id] = {
                        label: categoryLabel,
                        isSelected: false,
                        rawName: category.name,
                        minQuantityType: category.min_quantity_type,
                      }
                      return result
                    },
                    {})
                    upsells.inventory = response.data.inventory
                    Object.values(upsells.inventory).forEach(upsellInventory => {
                        var inventoryCategoryId = upsellInventory.category_id
                        if (!upsellInventory.deleted && inventoryCategoryId in upsells.categories) {
                            var categoryName = upsells.categories[inventoryCategoryId].rawName
                            if (!(inventoryCategoryId in upsellsToInventory)) {
                                upsellsToInventory[inventoryCategoryId] = []
                            }
                            var inventory = {
                                id: upsellInventory.id,
                                name: upsellInventory.name,
                                categoryName: categoryName,
                                categoryId: inventoryCategoryId,
                                quantityEqualType: upsellInventory.quantityEqualType ? upsellInventory.quantityEqualType : 'PARTY_SIZE',
                                quantityNum: upsellInventory.quantityNum ? upsellInventory.quantityNum : 1,
                                isSelected: false,
                            }
                            upsellsToInventory[inventoryCategoryId].push(inventory)
                        }
                    });
                }
            },
            error: function(jqXHR, textStatus, error) {
                Interface._alert('Unknown error happened. Please try later.');
            }
        });
    };

    var fetch_experiences = function (venue_name, experiences, on_success) {
        $.ajax({
            url: '/api-yoa/experiences',
            data: {
                venue: venue_name,
            },
            success: function (response) {
                if (response.status != '200') {
                    Interface._alert(response.msg);
                } else {
                    experiences = _.reduce(response.data.results, (result, experience) => {
                        result[experience.id] = experience
                        return result
                    }, {});
                    experiences = _.sortBy(experiences, (experience)=>experience.status)
                    on_success(experiences);
                }
            },
            error: function (jqXHR, textStatus, error) {
                Interface._alert('Unknown error happened. Please try later.');
            }
        });
    };

    var build_audience_name_lookup = function(audience_hierarchy) {
      var reduce_children = function(name_lookup, node) {
        name_lookup[node.value] = node.name;
        if (node.children) {
          _.reduce(node.children, reduce_children, name_lookup);
        }
        return name_lookup;
      };
      return _.reduce(audience_hierarchy, reduce_children, {});
    }

    var build_value_by_shift_times_display = function(start_end_times_by_shift_display, shift_categories) {
        shift_categories = sort_shift_categories(shift_categories)
        return _.compact(_.map(shift_categories, function(shift_category, i) {
            if (shift_category in start_end_times_by_shift_display) {
                return (
                    Pmp.Utils.timeWithLocale(start_end_times_by_shift_display[shift_category][0]) +
                    ' - ' +
                    Pmp.Utils.timeWithLocale(start_end_times_by_shift_display[shift_category][1])
                )
            }
        })).join(', ');
    };

    var sort_shift_categories = function (shift_categories) {
        shift_categories.sort(function(a, b) {
            if (SHIFT_CATEGORY_SORT[a] < SHIFT_CATEGORY_SORT[b]) {
                return -1
            }
            if (SHIFT_CATEGORY_SORT[a] > SHIFT_CATEGORY_SORT[b]) {
                return 1
            }
            return 0
        })
        return shift_categories
    }

    var build_shift_categories_display = function (access_time_type, restrict_to_shifts, shift_categories) {
        if (access_time_type !== 'ALL' && !restrict_to_shifts) {
            return 'Any'
        }
        shift_categories = sort_shift_categories(shift_categories)
        return _.map(shift_categories, function(shift_category, i) {
            return shiftCategoryOptions[shift_category]
        }).join(', ')
    }

    return {
        shiftCategoryOptions: shiftCategoryOptions,
        fetch_data: fetch_data,
        fetch_upsells: fetch_upsells,
        fetch_experiences: fetch_experiences,
        build_audience_name_lookup: build_audience_name_lookup,
        build_value_by_shift_times_display: build_value_by_shift_times_display,
        build_shift_categories_display: build_shift_categories_display
    };
}();
